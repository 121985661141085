import React from "react";
import styled from "styled-components";
import { JobsSearchTile, TileNavigation } from "../components/tile-navigation";
import { Headline2 } from "../components/typography";
import { graphql, PageProps } from "gatsby";
import Social from "../components/social/social";
import { JobOffersCountInterface } from "../graphql/jobs";
import Layout from "../components/layout";
import { ContactPageContentInterface } from "../graphql/contact-page";
import { PimcoreImage } from "../components/pimcore-image";

interface ContactPageDataInterface {
  pimcore: {
    content: ContactPageContentInterface;
    jobOffersCount: JobOffersCountInterface;
  }
}

interface TitleProps {
  light?: boolean | null;
  yellow?: boolean | null;
  marginBottom?: boolean | null;
}

const ContactPage: React.FC<PageProps<ContactPageDataInterface>> = ({ data }) => {
  const content = data.pimcore.content;

  return (
    <Layout
      navCurrentItem="contact"
      title={content.metaTitle}
      description={content.metaDescription}
      canonical={content.metaCanonical}
      openGraph={{ title: content.metaOgTitle, description: content.metaOgTitle, image: content.metaOgImage }}
      schemaOrg={content.metaSchema}
    >
      <Main>
        <Header>
          <PimcoreImage
            image={content.background}
            mobileImage={content.backgroundMobile}
            modifier="w-full h-full"
            loading="eager"
            withAspectRatio
          />
          <ContentWrapper>
            <Wrapper2>
              <HeadlineWrapper>
                <Headline2 as="h1" dangerouslySetInnerHTML={{ __html: content.headline }} />
              </HeadlineWrapper>
              <AddressWrapper>
                <Title as="h2" dangerouslySetInnerHTML={{ __html: content.addressLine1 }} />
                <Title light dangerouslySetInnerHTML={{ __html: content.addressLine2 }} />
                <ButtonLinkOutline href={content.navigationUrl} target="_blank" rel="nofollow noreferrer">
                  Navigate
                </ButtonLinkOutline>
              </AddressWrapper>
            </Wrapper2>
            <Wrapper3>
              <ContactWrapper>
                <Title as="h2" marginBottom>
                  <strong>Contact us</strong>
                </Title>
                <Title light yellow>
                  <a href={`mailto:${content.contactEmail}`} target="_blank">{content.contactEmail}</a>
                </Title>
              </ContactWrapper>
              <SocialWrapper>
                <Social linkedin />
              </SocialWrapper>
            </Wrapper3>
          </ContentWrapper>
        </Header>
        <TileNavigation top={"team"} bottom={"office"}>
          <JobsSearchTile jobsCount={data.pimcore.jobOffersCount.totalCount} />
        </TileNavigation>
      </Main>
    </Layout>
  );
};

const Header = styled.header.attrs({
  className: "w-full h-full relative pt-12 tablet:pt-0",
})``;
const Main = styled.main.attrs({ className: "" })``;
const Wrapper2 = styled.div.attrs({ className: "" })``;
const Wrapper3 = styled.div.attrs({ className: "" })``;
const ContentWrapper = styled.div.attrs({
  className:
    "absolute right-5 tablet:right-auto left-5 tablet:left-40 top-24 tablet:top-36 bottom-9 tablet:bottom-20 tablet:w-1/2 flex flex-col justify-between",
})``;
const HeadlineWrapper = styled.div.attrs({ className: "-mb-3 tablet:mb-0 tablet:pb-4" })``;
const AddressWrapper = styled.div.attrs({ className: "" })``;
const ContactWrapper = styled.div.attrs({ className: "tablet:mb-4" })``;

const SocialWrapper = styled.div.attrs({
  className: "hidden tablet:block flex",
})``;
const Title = styled.div.attrs<TitleProps>(
  ({ light, yellow, marginBottom }) => ({
    className: `${yellow ? "text-yellow" : "text-white"
      } text-benefit tablet:text-paragraphTablet ${light ? "font-light" : ""} ${marginBottom ? "mb-2" : ""
      }`,
  })
) <TitleProps>``;
const ButtonLinkOutline = styled.a.attrs({
  className:
    "inline-block text-sm px-7 py-4 border-solid border-2 border-yellow text-yellow font-bold rounded-4xl transition-colors bg-transparent hover:bg-yellow hover:text-white mt-4 tablet:mt-6",
})``;

export const query = graphql`
  query {
    pimcore {
      ...contactPageData
      ...jobOffersCount
    }
  }
`;


export default ContactPage;